import { ElementAttributes } from '../../../applications/itemEditor/constants/elementAttributes.ts';
import { DataDraftJsAttributes } from './DataDraftJsAttributes.ts';
import { DataUiAttributes, ObjectWithDataAttribute } from './DataUiAttributes.ts';

export enum DataAttributes {
  Badge = 'data-badge',
  RteColumnIndex = 'data-rte-column-index',
  Rate = 'data-rate',
  CommentsOnRemovedContentHeader = 'data-comments-on-removed-content-header',
  CommentThreadId = 'data-comment-thread-id',
  SkipTabOrderWithin = 'data-skip-tab-order-within',
  FocusEntryPoint = 'data-focus-entry-point',
}

export type DataAttributeName =
  | DataUiAttributes
  | DataAttributes
  | DataDraftJsAttributes
  | ElementAttributes;

export const getDataAttributeSelector = (
  name: DataAttributeName,
  value: string | number | undefined,
): string => (value ? `[${name}=${CSS.escape(value.toString())}]` : `[${name}]`);

export const getDataAttribute = (
  name: DataAttributeName,
  value: string | number | undefined,
): ObjectWithDataAttribute => (value ? { [name]: value } : {});
